import { useCallback, useState } from 'react';
import { ICNotification } from "../icons/notification"
import { ICGlobal } from "../icons/global"
import classNames from 'classnames'
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../redux/reducers"
import { getURL } from "../utils"
import { Dropdown, Modal, MenuProps } from 'antd';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../api/auth";
import { setLoading } from "../redux/slices/appInfo"
import { removeUserInfo } from "../redux/actions";
import { useTranslation } from "react-i18next";
import { ICCheckFill, ICLogo, ICLogoutUser, ICUserV2 } from "./../icons";
import { useSticky } from '../hooks/useSticky';
import { HRTooltip } from '../components/tooltip';
import { LANGUAGE } from '../utils/constants';
import { useGeneralPageData } from '../pages/general';
import styled from 'styled-components';
import { HRButton } from '../components/button';

const WrapperMenu = styled.div`
`;

const LINKS = [{
    title: "Recruitment",
    link: "/recruitment/job-posting"
}, {
    title: "Candidates",
    link: ""
}, {
    title: "Statistics",
    link: "/statistics"
}];

const MAIN_PAGES = [
    {
        KEYWORD: ['recruitment'],
        LINK: '/recruitment'
    },
    {
        KEYWORD: ['library', 'test'],
        LINK: '/library'
    },
    {
        KEYWORD: ['candidate'],
        LINK: '/my-candidate'
    }
];

export const MainHeader: React.FC = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isSticky, element } = useSticky();
    const { setShowComingSoonModal } = useGeneralPageData();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const userName = userInfo.name ?? userInfo.email;

    const [open, setOpen] = useState(false);

    const onLogoutUser = useCallback(async () => {
        dispatch(setLoading(true));
        try {
            await logout();
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);

    const showConfirmModal = () => {
        setOpen(true);
    };
    const hideConfirmModal = () => {
        setOpen(false);
    };
    const onLogout = useCallback(() => {
        setOpen(false);
        onLogoutUser();
        dispatch(removeUserInfo());
        navigate("/login");
    }, [dispatch, navigate, onLogoutUser]);

    const onChangeLanguages: MenuProps['onClick'] = ({ key }) => {
        if (key === 'en') {
            setShowComingSoonModal(true);
            return;
        }
        localStorage.setItem("language", key);
        i18n.changeLanguage(key);
    };
    const onClickUser: MenuProps['onClick'] = ({ key }) => {
        if (key === 'sign_out') {
            showConfirmModal();
        }
    };

    const userList = [
        {
            label: (
                <div className="flex flex-row gap-[16px] items-center justify-start font-bold p-[16px]">
                    <img alt="" src={getURL(userInfo.avatar || '')} className="w-[40px] h-[40px] rounded-full" />
                    <span className="line-clamp-1 text-high-em max-w-[150px]">{userInfo.name ?? userInfo.email}</span>
                </div>
            ),
            key: 'Welcome'
        },
        {
            label: (
                <Link to={'/profile'} style={{
                    color: '#110C22',
                    fontWeight: 500,
                }}>
                    <div className="flex flex-row gap-[16px] items-center justify-start p-[16px] text-standard-medium">
                        <ICUserV2 width={24} height={24} />
                        Hồ sơ của tôi
                    </div>
                </Link>
            ),
            key: 'my_profile'

        },
        {
            label: (
                <div className="flex flex-row gap-[16px] items-center justify-start p-[16px] text-standard-medium text-med-em">
                    <ICLogoutUser />
                    <span className="text-[#F03D3D]" onClick={showConfirmModal}>Đăng xuất</span>
                </div>
            ), key: 'sign_out'
        },
    ];
    const languages = [
        {
            label: (
                <div className="grid grid-cols-[1fr_auto] gap-[8px]"><span>Tiếng Việt</span>{i18n.language === LANGUAGE.VN && <ICCheckFill width={22} height={22} />}</div>
            ), key: LANGUAGE.VN
        },
        {
            label:
                (<div className="grid grid-cols-[1fr_auto] gap-[8px]"><span>Tiếng Anh</span>{i18n.language === LANGUAGE.EN && <ICCheckFill width={22} height={22} />}</div>),
            key: LANGUAGE.EN
        },
    ];
    
    const getLinkClass = (link: string) => {
        const BASE_CLASS = "flex h-full items-center text-standard-medium border-b-[3px] hover:border-purple hover:text-primary";
        const routerLink = MAIN_PAGES.find(page => page?.KEYWORD.some(keyword => location.pathname.toString().includes(keyword)));
        const activeLink = routerLink?.LINK === link;
        return classNames([
            BASE_CLASS,
            activeLink ? "text-primary border-purple" : "text-med-em border-transparent"
        ])
    };

    return (
        <div className="container mx-auto relative">
            <Modal
                title="Thông báo"
                open={open}
                centered={true}
                onCancel={hideConfirmModal}
                okText="Có"
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[10px]">
                        <HRButton
                            btnSize="sm"
                            btnType="sub"
                            onClick={() => setOpen(false)}>
                            Không
                        </HRButton>
                        <HRButton btnSize="sm" onClick={onLogout}>
                            Có
                        </HRButton>
                    </div>
                }
            >
                <p>Bạn có chắc chắn muốn đăng xuất?</p>
            </Modal>
            {/* isSticky ? "header-sticky" : "" */}
            <div ref={element} className={classNames("h-[70px] flex shadow-e-02 overflow-hidden box-border header-sticky")}>
                <div className="container mx-auto flex justify-between">
                    <div className="flex space-x-6 items-center h-full">
                        <Link to={'/'}>
                            {/* <img src="/images/logo.jpg" alt="logo" className="h-[50px] w-[119px]" /> */}
                            <ICLogo />
                        </Link>
                        {
                            LINKS.map((link, index) => {
                                return (
                                    <div key={index} className="flex items-center h-full">
                                        {link?.link && <Link to={link?.link} className={getLinkClass(link?.link)}>
                                            {link.title}
                                        </Link>}
                                        {!link?.link && <div className={`${getLinkClass(link?.link as string)} cursor-pointer`}>
                                            <span onClick={() => setShowComingSoonModal(true)}>
                                                {link.title}
                                            </span>
                                        </div>}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex items-center gap-[16px]">
                        <div className="relative flex items-center p-[8px]">
                            <Dropdown menu={{ items: languages, onClick: onChangeLanguages }}
                                overlayStyle={{ width: '206px' }}
                                trigger={['click']} arrow>
                                <span onClick={e => e.preventDefault()}>
                                    <ICGlobal />
                                </span>
                            </Dropdown>
                        </div>
                        <span className="cursor-pointer p-[8px]" onClick={() => setShowComingSoonModal(true)}>
                            <ICNotification />
                        </span>
                        <WrapperMenu className="flex items-center font-bold pl-[16px]">
                            <Dropdown menu={{ items: userList, onClick: onClickUser }}
                                overlayClassName="user-dropdown"
                                overlayStyle={{ width: '272px', minWidth: '272px', borderRadius: '12px', color: '#110C22', fontWeight: 500 }}
                                placement="bottomRight"
                                className='cursor-pointer'
                                trigger={['click']} arrow>
                                <div onClick={e => e.preventDefault()}>
                                    <div className="flex gap-[19px] items-center justify-center">
                                        <div>
                                            <HRTooltip
                                                placement="left"
                                                content={userName || ''}
                                                childNode={(
                                                    <span className="line-clamp-1 text-body-medium text-high-em max-w-[150px]">{userInfo.name ?? userInfo.email}</span>
                                                )}
                                            >
                                            </HRTooltip>
                                        </div>
                                        <div className="w-[48px] h-[48px] min-w-[48px] rounded-[32px] box-border shadow-l-small">
                                            <img alt="user" src={getURL(userInfo.avatar || '')} className="border-[3px] border-solid border-white w-full h-full rounded-full box-border" />
                                        </div>
                                    </div>
                                </div>
                            </Dropdown>
                        </WrapperMenu>

                    </div>
                </div>
            </div>
        </div>
    )
}
