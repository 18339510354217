import { Link, useLocation } from "react-router-dom"
import { SecondaryMenu, SecondaryMenuItem } from "../../components/SecondaryMenu";
import { FunnelPlotOutlined, GlobalOutlined, OpenAIOutlined } from "@ant-design/icons";
import { JobPosting } from "./job-posting";
import { HiringProcess } from "./hiring-process";
import { RecruitementTools } from "./tools";
enum RecruitmentPageKey {
    JobPosting = "job-posting",
    HiringProcess = "hiring-process",
    TOOL = "tools",
}
const items: SecondaryMenuItem[] = [
    {
        label: <Link to="/recruitment/job-posting">Job Posting</Link>,
        key: RecruitmentPageKey.JobPosting,
        icon: <GlobalOutlined />
    },
    {
        label: <Link to="/recruitment/hiring-process">Hiring Process</Link>,
        key: RecruitmentPageKey.HiringProcess,
        icon: <FunnelPlotOutlined />
    },
    {
        label: <Link to="/recruitment/tools">Tools</Link>,
        key: RecruitmentPageKey.TOOL,
        icon: <OpenAIOutlined />
    },
]
export const RecruitmentPage: React.FC = () => {
    const { pathname } = useLocation();
    const current = pathname.split("/")[2] ?? RecruitmentPageKey.JobPosting;
    return <div>
        <SecondaryMenu
            items={items}
            current={current}
        />
        {
            current === RecruitmentPageKey.JobPosting && <JobPosting />
        }
        {
            current === RecruitmentPageKey.HiringProcess && <HiringProcess />
        }
        {
            current === RecruitmentPageKey.TOOL && <RecruitementTools />
        }
    </div>
}