import { Button, Input } from "antd";
import Modal from "antd/lib/modal";
import { HRButton } from "../../../components/button";
import { ICClose, ICUpload, ICUploadV2 } from "../../../icons";
import React from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/appInfo";
import { useNotification } from "../../../hooks/useNotification";
import { TCandidate, WorkExperience } from "../type";
import TextArea from "antd/lib/input/TextArea";
import { MOCKCANDIDATE } from "../../../hooks/repository/mock";
import { extractCVApi } from "../../../api/gpt";

type AddCandidateModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onAddCandidate: (candidate: TCandidate) => void;
}
const EMPTY_CANDIDATE: TCandidate = {
    _id: "",
    full_name: "",
    email: "",
    date_of_birth: "",
    phone_number: "",
    address: "",
    years_of_experience: 0,
    work_experience: [],
    education: [],
    skills: [],
    certifications: [],
    projects: [],
    languages: "",
    hobbies: "",
    references: "",
    summary: ""
}
export const AddCandidateModal: React.FC<AddCandidateModalProps> = ({
    isOpen,
    onClose,
    onAddCandidate
}) => {
    const [candidateInfo, setCandidateInfo] = React.useState<Partial<TCandidate> | undefined>();
    const { showError } = useNotification();
    const dispatch = useDispatch();
    const [step, setStep] = React.useState(0);
    const [addType, setAddType] = React.useState<"manual" | "import">("manual");
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [file, setFile] = React.useState<File | null>(null);
    const onUploadFile = React.useCallback(() => {
        const file = fileInputRef.current?.files?.[0];
        if (file) {
            setFile(file);
        }
    }, []);
    const onConfirmUpload = React.useCallback(async () => {
        if (!file) return;
        dispatch(setLoading(true));
        try {
            const data = await extractCVApi(file);
            setCandidateInfo(data);
            setStep(2);
        } catch (e: any) {
            showError("Failed to upload CV", e?.message as string);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [file]);
    return <Modal
        title="Add Candidate Information"
        open={isOpen}
        centered={true}
        onCancel={onClose}
        width={800}
        footer={
            <div className="flex justify-end gap-[16px]">
                <span>
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={onClose}>
                        <div className="flex items-center space-x-2 justify-center">
                            <span>
                                <ICClose fill="#2E293D" width={24} height={24} />
                            </span>
                            <span>Cancel</span>
                        </div>
                    </HRButton>
                </span>
                <span>
                    <HRButton
                        btnSize="sm"
                        disabled={step !== 2}
                        onClick={() => {
                            if (candidateInfo) {
                                onAddCandidate(candidateInfo as TCandidate);
                            }
                            onClose();
                        }}
                    >
                        <div className="flex space-x-2 items-center justify-center">
                            <span>
                                <ICUploadV2 />
                            </span>
                            <span>Add</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        }
    >
        {
            step === 0 && <div className="flex space-x-4">
                <div
                    onClick={() => {
                        setCandidateInfo(EMPTY_CANDIDATE);
                        setStep(2);
                    }}
                    className="select-none w-1/2 text-center rounded-lg border h-[80px] flex items-center justify-center cursor-pointer font-semibold hover:bg-[#C8C8F9]"
                >
                    Manual Fill Form
                </div>
                <div
                    onClick={() => {
                        setAddType("import");
                        setStep(1);
                    }}
                    className="select-none w-1/2 text-center rounded-lg border h-[80px] flex items-center justify-center cursor-pointer font-semibold hover:bg-[#C8C8F9]"
                >
                    Import from CV
                </div>
            </div>
        }
        {
            step === 1 && addType === "import" && <div>
                <div className="flex flex-col gap-[16px]">
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".pdf,.doc,.docx"
                        onChange={onUploadFile}
                    />
                    <div>
                        <Button
                            onClick={() => {
                                fileInputRef.current?.click();
                            }}
                            icon={<ICUpload />}
                            className="flex items-center gap-[8px]">
                            <span>Upload</span>
                        </Button>
                        <span className="text-[10px]">Only accept .pdf, .docx, .doc</span>
                    </div>
                    {
                        file && <div>
                            <span className="text-[12px]">{file.name}</span>
                            <div>
                                <Button
                                    type="primary"
                                    onClick={onConfirmUpload}
                                    icon={<ICUploadV2 />}
                                    className="flex items-center gap-[8px]">
                                    <span>Upload CV</span>
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
        {
            step === 2 && <CandidateCard
                candidate={candidateInfo}
                setCandidate={setCandidateInfo}
            />
        }
    </Modal>
}

type CandidateCardProps = {
    candidate: Partial<TCandidate> | undefined,
    setCandidate: React.Dispatch<React.SetStateAction<Partial<TCandidate> | undefined>>
}
const CandidateCard: React.FC<CandidateCardProps> = ({
    candidate,
    setCandidate
}) => {
    if (!candidate) return null;
    return <div
        className="space-y-3 hr-scroll-bar"
        style={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 200px)",
        }}
    >
        <div className="grid grid-cols-2 gap-3">
            <MyInput
                label="Full Name"
                keyOf="full_name"
                allValues={candidate}
                setAllValues={setCandidate}
            />
            <MyInput
                label="Email"
                keyOf="email"
                allValues={candidate}
                setAllValues={setCandidate}
            />
            <MyInput
                label="Date of Birth"
                keyOf="date_of_birth"
                allValues={candidate}
                setAllValues={setCandidate}
            />
            <MyInput
                label="Phone Number"
                keyOf="phone_number"
                allValues={candidate}
                setAllValues={setCandidate}
            />
            <MyInput
                label="Address"
                keyOf="address"
                allValues={candidate}
                setAllValues={setCandidate}
            />
            <MyInput
                label="Years of Experience"
                keyOf="years_of_experience"
                allValues={candidate}
                setAllValues={setCandidate}
            />
        </div>
        <div>
            <label className="text-[12px] font-semibold">Summary</label>
            <TextArea
                value={candidate.summary}
                onChange={(e) => {
                    setCandidate(prev => ({
                        ...prev,
                        summary: e.target.value
                    }))
                }}
            />
        </div>
        <hr />
        <div>
            <label className="text-[12px] font-semibold">
                Work Experience
            </label>
            <div className="space-y-3">
                {
                    (candidate.work_experience ?? []).map((workExperience, index) => {
                        return <WorkExperienceRenderer
                            key={index}
                            workExperience={workExperience}
                            setWorkExperience={(workExperience) => {
                                setCandidate(prev => ({
                                    ...prev,
                                    work_experience: [workExperience]
                                }))
                            }}
                        />
                    })
                }
            </div>
        </div>
        <hr />
        <div>
            <label className="text-[12px] font-semibold">Skills</label>
            <TextArea
                value={candidate.skills?.join(", ")}
                onChange={(e) => {
                    setCandidate(prev => ({
                        ...prev,
                        skills: e.target.value.split(", ")
                    }))
                }}
            />
        </div>
    </div>
}

type MyInputProps<T extends Record<string, any> = TCandidate> = {
    label: string;
    keyOf: keyof T;
    allValues: Partial<T>;
    setAllValues: React.Dispatch<React.SetStateAction<Partial<T> | undefined>>;
}
const MyInput: React.FC<MyInputProps> = ({
    label,
    keyOf,
    allValues,
    setAllValues
}) => {
    return <div>
        <label className="text-[12px] font-semibold">{label}</label>
        <Input
            className="text-[13px]"
            value={allValues[keyOf] as string}
            onChange={(e) => {
                setAllValues(prev => ({
                    ...prev,
                    [keyOf]: e.target.value
                }))
            }}
        />
    </div>
}

type WorkExperienceProps = {
    workExperience: WorkExperience;
    setWorkExperience: (workExperience: WorkExperience) => void;
}
const WorkExperienceRenderer: React.FC<WorkExperienceProps> = ({
    workExperience,
    setWorkExperience
}) => {
    return <div className="space-y-3">
        <div className="grid grid-cols-2 gap-3">
            <div>
                <label className="text-[12px] font-semibold">Title</label>
                <Input
                    className="text-[13px]"
                    value={workExperience.title}
                    onChange={(e) => {
                        setWorkExperience({
                            ...workExperience,
                            title: e.target.value
                        })
                    }}
                />
            </div>
            <div>
                <label className="text-[12px] font-semibold">Company</label>
                <Input
                    className="text-[13px]"
                    value={workExperience.company}
                    onChange={(e) => {
                        setWorkExperience({
                            ...workExperience,
                            company: e.target.value
                        })
                    }}
                />
            </div>
            <div>
                <label className="text-[12px] font-semibold">Start Date</label>
                <Input
                    className="text-[13px]"
                    value={workExperience.start_date}
                    onChange={(e) => {
                        setWorkExperience({
                            ...workExperience,
                            start_date: e.target.value
                        })
                    }}
                />
            </div>
            <div>
                <label className="text-[12px] font-semibold">End Date</label>
                <Input
                    className="text-[13px]"
                    value={workExperience.end_date}
                    onChange={(e) => {
                        setWorkExperience({
                            ...workExperience,
                            end_date: e.target.value
                        })
                    }}
                />
            </div>
        </div>
        <div>
            <label className="text-[12px] font-semibold">Description</label>
            <TextArea
                value={workExperience.description}
                onChange={(e) => {
                    setWorkExperience({
                        ...workExperience,
                        description: e.target.value
                    })
                }}
            />
        </div>
    </div>
}