export enum EJobPostingStatus {
    OPEN = "Open",
    PENDING = "Pending",
    CLOSED = "Closed"
}
export type TJobPosting = {
    _id: string;
    title: string;
    department: string;
    position: string;
    status: EJobPostingStatus;
    createdAt: number;
    totalCandidates: number;
    requiredSkills: string[];
    description: string;
    responsibilities: string;
}
export enum ECandidateStatus {
    APPLIED = "Applied",
    PHONE_SCREEN = "Phone Screen",
    INTERVIEWED = "Interviewed",
    OFFERED = "Offered",
    REJECTED = "Rejected",
    HIRED = "Hired"
}

export type TJobCandidate = TCandidate & {
    status: ECandidateStatus;
    date_of_application: string;
    jobPostingId: string;
    score?: number;
    resume: string;
}

export type WorkExperience = {
    title: string
    company: string
    start_date: string
    end_date: string
    description: string
}
export type Education = {
    degree: string,
    major: string,
    university: string,
    graduation_date: string,
}
export type TCandidate = {
    _id: string
    full_name: string,
    email: string,
    date_of_birth: string,
    phone_number: string,
    address: string,
    years_of_experience: number,
    work_experience: Array<WorkExperience>,
    education: Array<Education>,
    skills: string[],
    certifications: unknown[],
    projects: Array<{
        title: string,
        description: string,
        start_date: string,
        end_date: string,
        link: string
    }>,
    languages: string,
    hobbies: string,
    references: string,
    summary: string,
}