import { TCandidate } from "../../pages/recruitment/type";

export async function extractCVApi(
    file: File
): Promise<TCandidate> {
    const formData = new FormData();
    formData.append("file", file as Blob);
    const result = await fetch(process.env.REACT_APP_GPT_URL + "/extract-cv", {
        method: "POST",
        body: formData
    });
    return await result.json();
}

export async function cvScoringAPI(
    file: File,
    jobDescription: string
): Promise<{ score: number, general: string }> {
    const formData = new FormData();
    formData.append("file", file as Blob);
    formData.append("jobDescription", jobDescription);
    const result = await fetch(process.env.REACT_APP_GPT_URL + "/cv-scoring", {
        method: "POST",
        body: formData
    });
    return await result.json();
}