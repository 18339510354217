import { Drawer } from "antd";
import { TJobCandidate, WorkExperience } from "../type"
import TextArea from "antd/lib/input/TextArea";

type SelectedCandidatePanelProps = {
    candidate?: TJobCandidate;
    isOpen: boolean;
    onClose: () => void;
}
export const SelectedCandidateDrawer: React.FC<SelectedCandidatePanelProps> = ({
    isOpen,
    candidate,
    onClose
}) => {
    return <Drawer
        title={candidate?.full_name}
        onClose={onClose} open={isOpen}
        width={600}
    >
        <div className="space-y-3 hr-scroll-bar">
            <div className="grid grid-cols-2 gap-2">
                <div>
                    <span className="text-[12px] font-semibold">Name: </span>
                    <span>{candidate?.full_name}</span>
                </div>
                <div>
                    <span className="text-[12px] font-semibold">Email: </span>
                    <span>{candidate?.email}</span>
                </div>
                <div>
                    <span className="text-[12px] font-semibold">Date of Birth: </span>
                    <span>{candidate?.date_of_birth}</span>
                </div>
                <div>
                    <span className="text-[12px] font-semibold">Address: </span>
                    <span>{candidate?.address}</span>
                </div>
                <div>
                    <span className="text-[12px] font-semibold">Years of Experience: </span>
                    <span>{candidate?.years_of_experience}</span>
                </div>
                <div>
                    <span className="text-[12px] font-semibold">Phone number: </span>
                    <span>{candidate?.phone_number}</span>
                </div>
            </div>
            <div>
                <label className="text-[12px] font-semibold">Summary</label>
                <TextArea
                    value={candidate?.summary}
                    readOnly
                    rows={3}
                    wrap="soft"
                />
            </div>
            <hr />
            <div>
                <label className="text-[14px] font-bold">
                    Work Experience
                </label>
                <div className="space-y-3">
                    {
                        (candidate?.work_experience ?? []).map((workExperience, index) => {
                            return <WorkExperienceRenderer
                                key={index}
                                workExperience={workExperience}
                            />
                        })
                    }
                </div>
            </div>
            <hr />
            <div>
                <label className="text-[14px] font-bold">Skills</label>
                <TextArea
                    value={candidate?.skills?.join(", ")}
                    readOnly
                    rows={3}
                />
            </div>
        </div>
    </Drawer>
}

type WorkExperienceProps = {
    workExperience: WorkExperience;
}
const WorkExperienceRenderer: React.FC<WorkExperienceProps> = ({
    workExperience
}) => {
    return <div className="space-y-1">
        <div className="grid grid-cols-2 gap-2 gap-y-1">
            <div>
                <label className="text-[12px] font-semibold">Title: </label>
                <span>{workExperience.title}</span>
            </div>
            <div>
                <label className="text-[12px] font-semibold">Company: </label>
                <span>{workExperience.company}</span>
            </div>
            <div>
                <label className="text-[12px] font-semibold">Start Date: </label>
                <span>{workExperience.start_date}</span>
            </div>
            <div>
                <label className="text-[12px] font-semibold">End Date: </label>
                <span>{workExperience.end_date}</span>
            </div>
        </div>
        <div>
            <label className="text-[12px] font-semibold">Description</label>
            <TextArea
                value={workExperience.description}
                readOnly
                rows={3}
            />
        </div>
    </div>
}