import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.min.css';

import './tailwind.css';
import './variables.css';
import './index.css';
import "./fonts.css";

import './assets/fonts/fonts.d.ts';
import GlobalFont from './styles/globalFont';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from "./redux/store";


//Import i18n.ts
import "./i18n";
import { GeneralPage } from './pages/general';
import { NotSupportedPage } from './pages/not-supported';
import { NotFoundPage } from './pages/404';
import { detectMobileDevice } from './utils';
import { Loading } from './components/loading';
import { Unauthorized } from './pages/unauthorized';
import { LoginPage } from './pages/login';
import { RecruitmentPage } from './pages/recruitment';
import { StatisticsPage } from './pages/statistics';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: "/",
    element: <GeneralPage page={<RecruitmentPage />} isPrivate />,
  },
  {
    path: "/recruitment/*",
    element: <GeneralPage page={<RecruitmentPage />} isPrivate />,
  },
  {
    path: "/statistics",
    element: <GeneralPage page={<StatisticsPage />} isPrivate />,
  },
  {
    path: "/login",
    element: <Unauthorized page={<LoginPage />} />
  },
  {
    path: "/not-supported",
    element: <NotSupportedPage />
  },
  {
    path: "*",
    element: <NotFoundPage />
  }
]);

detectMobileDevice();
root.render(
  <Provider store={store}>
    <GlobalFont />
    <div className="App">
      <RouterProvider router={router} />
      <Loading />
    </div>
  </Provider>
)
