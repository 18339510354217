import React from "react";
import { HRSelect } from "../../../components/form/select";
import { useJobPosting } from "../../../hooks/repository/useJobPosting";
import { ICBack } from "../../../icons/back";
import { ECandidateStatus, TCandidate, TJobPosting } from "../type";
import { Button } from "antd";
import { ICUpload } from "../../../icons";
import { useCandidates } from "../../../hooks/repository/useCandidates";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigation } from "../../../hooks/useNavigate";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/appInfo";
import { cvScoringAPI, extractCVApi } from "../../../api/gpt";
function getScoreColor(score: number) {
    if (score >= 80) {
        return "#0F9960";
    }
    if (score >= 65) {
        return "#FFC107";
    }
    if (score >= 50) {
        return "#e1ae00"
    }
    return "#F5222D";
}
type ScoringResult = {
    candidate: Omit<TCandidate, "_id">;
    score: number;
    comment: string;
}
type CVScoringProps = {
    onBack: () => void;
}
export const CVScoring: React.FC<CVScoringProps> = ({
    onBack
}) => {
    const dispatch = useDispatch();
    const { navigate } = useNavigation();
    const [jobPostings, setJobPostings] = React.useState<TJobPosting[]>([]);
    const [selectedJobPosting, setSelectedJobPosting] = React.useState<string>();
    const { getJobPostings } = useJobPosting();
    const { createCandidate } = useCandidates();
    React.useEffect(() => {
        getJobPostings().then(jobs => {
            setJobPostings(jobs);
            setSelectedJobPosting(jobs[0]._id);
        });
    }, []);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [file, setFile] = React.useState<File | null>(null);
    const onUploadFile = React.useCallback(() => {
        const file = fileInputRef.current?.files?.[0];
        if (file) {
            setFile(file);
        }
    }, []);
    const [scoringResult, setScoringResult] = React.useState<ScoringResult | null>(null);
    const onScoring = React.useCallback(async () => {
        const job = jobPostings.find(job => job._id === selectedJobPosting);
        if (!job || !file) return;
        dispatch(setLoading(true));
        try {
            const [scoringResult, candidate] = await Promise.all([
                cvScoringAPI(file, job.description),
                extractCVApi(file)
            ]);
            if (!scoringResult || !candidate || scoringResult.score === undefined) {
                throw new Error("Failed to score CV");
            }
            setScoringResult({
                candidate,
                score: scoringResult.score,
                comment: scoringResult.general
            });
            if(fileInputRef.current) fileInputRef.current.value = "";
            setFile(null);
        } catch (e: any) {
            showError("Failed to upload CV", e?.message as string);
        } finally {
            dispatch(setLoading(false));
        }
    }, [file, jobPostings, selectedJobPosting]);
    const onProcessToHiring = React.useCallback(() => {
        if (!scoringResult || !selectedJobPosting) return;
        const candidateId = nanoid();
        createCandidate({
            ...scoringResult.candidate,
            _id: candidateId,
            jobPostingId: selectedJobPosting,
            status: ECandidateStatus.APPLIED,
            score: scoringResult.score,
            date_of_application: new Date().toISOString(),
            resume: file?.name || ""
        }).then(() => {
            navigate(`/recruitment/hiring-process?id=${selectedJobPosting}&candidateId=${candidateId}`);
        });
    }, [createCandidate, scoringResult, selectedJobPosting, navigate]);
    return <div className="space-y-8 text-left container mx-auto">
        <div className="flex items-center justify-between">
            <div className="space-x-2 flex items-center text-heading-5-semi-bold text-high-em">
                <span
                    className="p-2 rounded border cursor-pointer"
                    onClick={onBack}
                >
                    <ICBack />
                </span>
                <span>
                    CV Scoring
                </span>
            </div>
        </div>
        <div className="space-y-3">
            <HRSelect
                value={selectedJobPosting}
                onChange={(value) => setSelectedJobPosting(value)}
                label="Select Job Posting"
                options={
                    jobPostings.map((job) => ({
                        label: job.title,
                        value: job._id
                    }))
                }
            />
            <div className="flex flex-col gap-1">
                <label className="space-x-1 text-med-em text-body-medium">
                    <span>Upload CV File</span>
                </label>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".pdf,.doc,.docx"
                    onChange={onUploadFile}
                />
                <div>
                    <Button
                        onClick={() => {
                            fileInputRef.current?.click();
                        }}
                        icon={<ICUpload />}
                        className="flex items-center gap-[8px]">
                        <span>Upload</span>
                    </Button>
                    <span className="text-[10px]">Only accept .pdf, .docx, .doc</span>
                </div>
                {
                    file && <div>
                        <span className="text-[12px]">{file.name}</span>
                    </div>
                }
            </div>
            <div className="flex justify-center">
                <Button
                    type="primary"
                    disabled={!file}
                    onClick={onScoring}
                >
                    <span className="font-semibold">
                        Scoring the CV
                    </span>
                </Button>
            </div>
        </div>
        {
            scoringResult && <>
                <hr />
                <div className="space-y-3">
                    <div>
                        <span className="text-high-em font-semibold text-[18px]">Result</span>
                    </div>
                    <div className="space-x-2">
                        <span className="text-[13px] font-semibold">Candidate:</span>
                        <span className="font-bold">{scoringResult.candidate.full_name}</span>
                    </div>
                    <div className="space-x-2">
                        <span className="text-[13px] font-semibold">Score:</span>
                        <span className="font-bold text-green" style={{
                            color: getScoreColor(scoringResult.score)
                        }}>
                            {scoringResult.score}
                        </span>
                    </div>
                    <div className="space-x-2">
                        <span className="text-[13px] font-semibold">Comment:</span>
                        <span className="text-[14px]">{scoringResult.comment}</span>
                    </div>
                    <div className="flex justify-center">
                        <Button
                            type="primary"
                            onClick={onProcessToHiring}
                        >
                            <span className="font-semibold">
                                Process to Hiring Process
                            </span>
                        </Button>
                    </div>
                </div>
            </>
        }
    </div>

}

function showError(arg0: string, arg1: string) {
    throw new Error("Function not implemented.");
}
