import { useState } from "react";
import { EJobPostingStatus, TJobPosting } from "../type";
import { HRInput } from "../../../components/form/input";
import { HRButton, IconButton } from "../../../components/button";
import { ICAdd, ICUploadV2 } from "../../../icons";
import { HRTextArea } from "../../../components/form/textarea";
import { ICBack } from "../../../icons/back";
import { nanoid } from "@reduxjs/toolkit";

type CreateNewJobPostingProps = {
    onBack: () => void;
    onCreated: (jobPosting: TJobPosting) => void;
}
export const CreateNewJobPosting: React.FC<CreateNewJobPostingProps> = ({
    onBack,
    onCreated
}) => {
    const [title, setTitle] = useState("");
    const [department, setDepartment] = useState("");
    const [description, setDescription] = useState("");
    const [responsibilities, setResponsibilities] = useState("");
    const [requiredSkills, setRequiredSkills] = useState<string>("");
    const canCreate = title && department && description && responsibilities && requiredSkills;
    const onCreate = () => {
        onCreated({
            _id: nanoid(),
            createdAt: Date.now(),
            title,
            department,
            description,
            responsibilities,
            requiredSkills: requiredSkills.split(",").map(skill => skill.trim()),
            status: EJobPostingStatus.OPEN,
            position: title,
            totalCandidates: 0
        })
    }
    return <div className="pb-16 space-y-8 text-left container mx-auto">
        <div className="flex items-center justify-between">
            <div className="space-x-2 flex items-center text-heading-5-semi-bold text-high-em">
                <span
                    className="p-2 rounded border cursor-pointer"
                    onClick={onBack}
                >
                    <ICBack />
                </span>
                <span>
                    Create New Job Posting
                </span>
            </div>
            <div>
                <HRButton btnSize="md" disabled={!canCreate} onClick={onCreate}>
                    <div className="flex space-x-2 justify-center items-center">
                        <ICUploadV2 />
                        <span>Create</span>
                    </div>
                </HRButton>
            </div>
        </div>
        <div className="space-y-3">
            <HRInput
                label="Title"
                placeholder="Enter job title"
                value={title}
                onChange={e => setTitle(e.target.value)}
            />
            <div className="grid grid-cols-2 gap-4">
                <HRInput
                    label="Department"
                    placeholder="Enter department"
                    value={department}
                    onChange={e => setDepartment(e.target.value)}
                />
                <HRInput
                    label="Required Skills"
                    placeholder="Enter skills"
                    value={requiredSkills}
                    onChange={e => setRequiredSkills(e.target.value)}
                />
            </div>
            <HRTextArea
                label="Description"
                placeholder="Enter job description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                style={{
                    height: "160px"
                }}
            />
            <HRTextArea
                label="Responsibilities"
                placeholder="Enter job responsibilities"
                value={responsibilities}
                onChange={e => setResponsibilities(e.target.value)}
                style={{
                    height: "160px"
                }}
            />
        </div>
    </div>
}