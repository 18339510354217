import React from "react";
import { CVScoring } from "./scoring";
enum ERecruitmentTools {
    CVScoring = "CVScoring",
    Integration = "Integration",
    HRDashboard = "HRDashboard",
    TalentAssessment = "TalentAssessment",
    AutoGrowingTalentPool = "AutoGrowingTalentPool",
}
type RecruitmentTools = {
    type: ERecruitmentTools;
    title: string;
    description: string;
    image: string;
};
const recruitmentTools: RecruitmentTools[] = [
    {
        type: ERecruitmentTools.CVScoring,
        title: "CV Scoring",
        description: "Use AI to evaluate CVs and rank candidates based on their qualifications.",
        image: "/scoring.jpg"
    },
    {
        type: ERecruitmentTools.Integration,
        title: "Intergration",
        description: "Intergrate with third-party recruitment platforms like LinkedIn, Indeed,...",
        image: "/linkedin.png"
    },
    {
        type: ERecruitmentTools.HRDashboard,
        title: "HR Dashboard",
        description: "Manage all your recruitment activities in one place.",
        image: "/dashboard.jpg"
    },
    {
        type: ERecruitmentTools.TalentAssessment,
        title: "Talent Assessment",
        description: "Assessments platform to evaluate candidates.",
        image: "/assessment.jpg"
    },
    {
        type: ERecruitmentTools.AutoGrowingTalentPool,
        title: "Auto-Growing Talent Pool",
        description: "Leverage data analysis and predictive technologies for boosting recruitment efficiency",
        image: "/Talent_pool.png"
    }
]
export const RecruitementTools: React.FC = () => {
    const [selectedTool, setSelectedTool] = React.useState<RecruitmentTools | null>(null);
    return <div className="text-center select-none">
        <div className="pt-8 space-y-6 min-h-screen">
            {
                selectedTool?.type === ERecruitmentTools.CVScoring && <CVScoring onBack={() => setSelectedTool(null)} />
            }
            {
                !selectedTool && <div className="pb-16 space-y-8 text-left container mx-auto">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center text-heading-5-semi-bold text-high-em" >
                            <span>
                                Recruitment Tools
                            </span>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 gap-y-6">
                        {
                            recruitmentTools.map((tool) => <RecruitmentToolRenderer
                                key={tool.type}
                                tool={tool}
                                onSelected={(tool) => setSelectedTool(tool)}
                            />)
                        }
                    </div>
                </div>
            }

        </div>
    </div>
}
type RecruitmentToolRendererProps = {
    tool: RecruitmentTools;
    onSelected: (tool: RecruitmentTools) => void;
}
const RecruitmentToolRenderer: React.FC<RecruitmentToolRendererProps> = ({ tool, onSelected }) => {
    return <div
        onClick={() => onSelected(tool)}
        className="min-h-[120px] flex w-[360px] text-center border rounded-lg p-2 space-x-4 bg-white items-center shadow-md cursor-pointer hover:bg-[#c7d7ff]"
    >
        <div
            className="size-[60px] min-w-[60px]"
            style={{
                backgroundImage: `url(${tool.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        />
        <div className="text-left">
            <span className="font-bold">{tool.title}</span>
            <p className="text-[12px]">{tool.description}</p>
        </div>
    </div>
}