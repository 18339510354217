import React from "react";
import { TJobPosting } from "../../pages/recruitment/type";
import { mockJobPostings } from "./mock";

const inMemoryJobPostings: TJobPosting[] = [];
export const useJobPosting = () => {
    const getJobPostings = React.useCallback((): Promise<TJobPosting[]> => {
        return Promise.resolve(
            inMemoryJobPostings.concat(mockJobPostings)
        );
    }, []);

    const getDetailJobPosting = React.useCallback((jobId: string): Promise<TJobPosting> => {
        return Promise.resolve(
            inMemoryJobPostings.concat(mockJobPostings)
                .find(job => job._id === jobId) as TJobPosting
        );
    }, []);

    const createJobPosting = React.useCallback((jobPosting: TJobPosting): Promise<TJobPosting> => {
        inMemoryJobPostings.push(jobPosting);
        return Promise.resolve(jobPosting);
    }, []);

    return {
        getJobPostings,
        getDetailJobPosting,
        createJobPosting
    }
}