import React from "react";
import { TJobCandidate } from "../../pages/recruitment/type";
import { generateMockCandidate } from "./mock";

const inMemoryCandidates: TJobCandidate[] = [];
export const useCandidates = () => {
    const getCandidates = React.useCallback((jobId: string): Promise<TJobCandidate[]> => {
        return Promise.resolve(
            generateMockCandidate(jobId).concat(
                inMemoryCandidates.filter(candidate => candidate.jobPostingId === jobId)
            )
        );
    }, []);
    const createCandidate = React.useCallback((candidate: TJobCandidate): Promise<TJobCandidate> => {
        inMemoryCandidates.push(candidate);
        return Promise.resolve(candidate);
    }, []);
        
    return {
        getCandidates,
        createCandidate
    }
}