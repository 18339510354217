import { nanoid } from "@reduxjs/toolkit";
import { TJobPosting, EJobPostingStatus, TJobCandidate, ECandidateStatus } from "../../pages/recruitment/type";
import { formatLocaleDateString } from "../../utils/date";

export const mockJobPostings: TJobPosting[] = [
    {
        _id: '1',
        department: "IT",
        createdAt: new Date("2021-09-01").getTime(),
        position: "Frontend Developer",
        status: EJobPostingStatus.OPEN,
        title: "Frontend Developer",
        totalCandidates: 10,
        requiredSkills: ["ReactJS", "HTML/CSS", "Javascript"],
        description: "We are looking for a Frontend Developer to join our team. You will be responsible for developing web applications using ReactJS. You will work closely with the design team to implement new features and improve the user experience.",
        responsibilities: "Develop new user-facing features using ReactJS. Build reusable components and front-end libraries for future use. Ensure the technical feasibility of UI/UX designs. Optimize applications for maximum speed and scalability. Collaborate with other team members and stakeholders."
    },
    {
        _id: '2',
        department: "IT",
        createdAt: new Date("2021-08-16").getTime(),
        position: "Backend Developer",
        status: EJobPostingStatus.PENDING,
        title: "Backend Developer",
        totalCandidates: 5,
        requiredSkills: ["NodeJS", "Express", "MongoDB"],
        description: "We are looking for a Backend Developer to join our team. You will be responsible for developing server-side logic, defining and maintaining the central database, and ensuring high performance and responsiveness to requests from the front-end.",
        responsibilities: "Develop server-side logic using NodeJS. Define and maintain the central database. Ensure high performance and responsiveness to requests from the front-end. Collaborate with the front-end developers to integrate user-facing elements with server-side logic. Implement security and data protection."
    },
    {
        _id: '3',
        department: "IT",
        createdAt: new Date("2021-08-12").getTime(),
        position: "Fullstack Developer",
        status: EJobPostingStatus.CLOSED,
        title: "Fullstack Developer",
        totalCandidates: 3,
        requiredSkills: ["ReactJS", "NodeJS", "MongoDB"],
        description: "We are looking for a Fullstack Developer to join our team. You will be responsible for developing web applications from scratch, designing the overall architecture of the web application, and ensuring high performance and responsiveness to requests.",
        responsibilities: "Develop web applications from scratch. Design the overall architecture of the web application. Ensure high performance and responsiveness to requests. Collaborate with the design team to implement new features and improve the user experience. Work with the front-end developers to integrate user-facing elements with server-side logic."
    },
    {
        _id: '4',
        department: "IT",
        createdAt: new Date("2021-08-11").getTime(),
        position: "DevOps Engineer",
        status: EJobPostingStatus.OPEN,
        title: "DevOps Engineer",
        totalCandidates: 2,
        requiredSkills: ["Docker", "Kubernetes", "AWS"],
        description: "We are looking for a DevOps Engineer to join our team. You will be responsible for implementing and managing the tools and processes that enable the rapid deployment of software applications, ensuring high availability, scalability, and security.",
        responsibilities: "Implement and manage the tools and processes that enable the rapid deployment of software applications. Ensure high availability, scalability, and security. Collaborate with the development team to design and implement CI/CD pipelines. Monitor and optimize the performance of the infrastructure. Troubleshoot and resolve issues in the production environment."
    },
    {
        _id: '5',
        department: "Product",
        createdAt: new Date("2021-07-02").getTime(),
        position: "Product Manager",
        status: EJobPostingStatus.PENDING,
        title: "Product Manager",
        totalCandidates: 25,
        requiredSkills: ["Product Management", "Agile", "User Experience"],
        description: "We are looking for a Product Manager to join our team. You will be responsible for defining the product vision, strategy, and roadmap, and working with cross-functional teams to deliver high-quality products that meet the needs of our customers.",
        responsibilities: "Define the product vision, strategy, and roadmap. Work with cross-functional teams to deliver high-quality products. Conduct market research and competitor analysis. Gather and prioritize product requirements. Develop product pricing and positioning strategies."
    },
];

export function generateMockCandidate(jobPostingId: string): TJobCandidate[] {
    return [
        {
            _id: nanoid(),
            date_of_birth: formatLocaleDateString(new Date("1998-01-01").getTime()) as string,
            date_of_application: formatLocaleDateString(new Date("2024-08-02").getTime()) as string,
            email: "mock_01@gmail.com",
            jobPostingId,
            full_name: "Andrew",
            phone_number: "0123456789",
            resume: "https://www.linkedin.com/in/hoaidien93/",
            years_of_experience: 3,
            status: ECandidateStatus.PHONE_SCREEN,
            score: 80,
            address: "Hanoi, Vietnam",
            certifications: [],
            education: [
                {
                    degree: "Bachelor",
                    major: "Software Engineering",
                    university: "Hanoi University of Science and Technology",
                    graduation_date: "2020-07-01"
                }
            ],
            hobbies: "Reading book, playing football",
            projects: [
                {
                    title: "HR Management System",
                    description: "A system to manage employee information",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01",
                    link: ""
                }
            ],
            languages: 'English, Vietnamese, Japanese',
            references: "Mr. A, Mr. B",
            skills: [
                "ReactJS",
                "NodeJS",
                "Docker"
            ],
            summary: "I am a software engineer with 5 years of experience in web development. I have a strong background in frontend development with ReactJS and backend development with NodeJS. I am also familiar with Docker and Kubernetes. I am looking for a new opportunity to grow my career and learn new technologies.",
            work_experience: [
                {
                    company: "ABC Company",
                    title: "Software Engineer",
                    description: "Developed web applications using ReactJS and NodeJS. Worked with the team to design and implement new features. Participated in code review and testing.",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01"
                }
            ]

        },
        {
            _id: nanoid(),
            date_of_birth: formatLocaleDateString(new Date("1998-03-01").getTime()) as string,
            date_of_application: formatLocaleDateString(new Date("2024-08-02").getTime()) as string,
            email: "mock_02@gmail.com",
            jobPostingId,
            full_name: "Alex",
            phone_number: "0123456789",
            resume: "https://www.linkedin.com/in/hoaidien93/",
            years_of_experience: 2,
            status: ECandidateStatus.APPLIED,
            score: 72,
            address: "Hanoi, Vietnam",
            certifications: [],
            education: [
                {
                    degree: "Bachelor",
                    major: "Software Engineering",
                    university: "Hanoi University of Science and Technology",
                    graduation_date: "2020-07-01"
                }
            ],
            hobbies: "Reading book, playing football",
            projects: [
                {
                    title: "HR Management System",
                    description: "A system to manage employee information",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01",
                    link: ""
                }
            ],
            languages: 'English, Vietnamese, Japanese',
            references: "Mr. A, Mr. B",
            skills: [
                "ReactJS",
                "NodeJS",
                "Docker"
            ],
            summary: "I am a software engineer with 5 years of experience in web development. I have a strong background in frontend development with ReactJS and backend development with NodeJS. I am also familiar with Docker and Kubernetes. I am looking for a new opportunity to grow my career and learn new technologies.",
            work_experience: [
                {
                    company: "ABC Company",
                    title: "Software Engineer",
                    description: "Developed web applications using ReactJS and NodeJS. Worked with the team to design and implement new features. Participated in code review and testing.",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01"
                }
            ]

        },
        {
            _id: nanoid(),
            date_of_birth: formatLocaleDateString(new Date("1992-01-01").getTime()) as string,
            date_of_application: formatLocaleDateString(new Date("2024-08-02").getTime()) as string,
            email: "mock_03@gmail.com",
            jobPostingId,
            full_name: "Michael",
            phone_number: "0123456789",
            resume: "https://www.linkedin.com/in/hoaidien93/",
            years_of_experience: 1,
            status: ECandidateStatus.REJECTED,
            score: 60,
            address: "Hanoi, Vietnam",
            certifications: [],
            education: [
                {
                    degree: "Bachelor",
                    major: "Software Engineering",
                    university: "Hanoi University of Science and Technology",
                    graduation_date: "2020-07-01"
                }
            ],
            hobbies: "Reading book, playing football",
            projects: [
                {
                    title: "HR Management System",
                    description: "A system to manage employee information",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01",
                    link: ""
                }
            ],
            languages: 'English, Vietnamese, Japanese',
            references: "Mr. A, Mr. B",
            skills: [
                "ReactJS",
                "NodeJS",
                "Docker"
            ],
            summary: "I am a software engineer with 5 years of experience in web development. I have a strong background in frontend development with ReactJS and backend development with NodeJS. I am also familiar with Docker and Kubernetes. I am looking for a new opportunity to grow my career and learn new technologies.",
            work_experience: [
                {
                    company: "ABC Company",
                    title: "Software Engineer",
                    description: "Developed web applications using ReactJS and NodeJS. Worked with the team to design and implement new features. Participated in code review and testing.",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01"
                }
            ]

        },
        {
            _id: nanoid(),
            date_of_birth: formatLocaleDateString(new Date("1994-01-01").getTime()) as string,
            date_of_application: formatLocaleDateString(new Date("2024-08-02").getTime()) as string,
            email: "mock_04@gmail.com",
            jobPostingId,
            full_name: "John Doe",
            phone_number: "0123456789",
            resume: "https://www.linkedin.com/in/hoaidien93/",
            years_of_experience: 6,
            status: ECandidateStatus.OFFERED,
            score: 95,
            address: "Hanoi, Vietnam",
            certifications: [],
            education: [
                {
                    degree: "Bachelor",
                    major: "Software Engineering",
                    university: "Hanoi University of Science and Technology",
                    graduation_date: "2020-07-01"
                }
            ],
            hobbies: "Reading book, playing football",
            projects: [
                {
                    title: "HR Management System",
                    description: "A system to manage employee information",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01",
                    link: ""
                }
            ],
            languages: 'English, Vietnamese, Japanese',
            references: "Mr. A, Mr. B",
            skills: [
                "ReactJS",
                "NodeJS",
                "Docker"
            ],
            summary: "I am a software engineer with 5 years of experience in web development. I have a strong background in frontend development with ReactJS and backend development with NodeJS. I am also familiar with Docker and Kubernetes. I am looking for a new opportunity to grow my career and learn new technologies.",
            work_experience: [
                {
                    company: "ABC Company",
                    title: "Software Engineer",
                    description: "Developed web applications using ReactJS and NodeJS. Worked with the team to design and implement new features. Participated in code review and testing.",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01"
                }
            ]

        },
        {
            _id: nanoid(),
            date_of_birth: formatLocaleDateString(new Date("1999-03-01").getTime()) as string,
            date_of_application: formatLocaleDateString(new Date("2024-08-02").getTime()) as string,
            email: "mock_05@gmail.com",
            jobPostingId,
            full_name: "Tom",
            phone_number: "0123456789",
            resume: "https://www.linkedin.com/in/hoaidien93/",
            years_of_experience: 2,
            status: ECandidateStatus.APPLIED,
            score: 65,
            address: "Hanoi, Vietnam",
            certifications: [],
            education: [
                {
                    degree: "Bachelor",
                    major: "Software Engineering",
                    university: "Hanoi University of Science and Technology",
                    graduation_date: "2020-07-01"
                }
            ],
            hobbies: "Reading book, playing football",
            projects: [
                {
                    title: "HR Management System",
                    description: "A system to manage employee information",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01",
                    link: ""
                }
            ],
            languages: 'English, Vietnamese, Japanese',
            references: "Mr. A, Mr. B",
            skills: [
                "ReactJS",
                "NodeJS",
                "Docker"
            ],
            summary: "I am a software engineer with 5 years of experience in web development. I have a strong background in frontend development with ReactJS and backend development with NodeJS. I am also familiar with Docker and Kubernetes. I am looking for a new opportunity to grow my career and learn new technologies.",
            work_experience: [
                {
                    company: "ABC Company",
                    title: "Software Engineer",
                    description: "Developed web applications using ReactJS and NodeJS. Worked with the team to design and implement new features. Participated in code review and testing.",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01"
                }
            ]

        },
        {
            _id: nanoid(),
            date_of_birth: formatLocaleDateString(new Date("2001-03-01").getTime()) as string,
            date_of_application: formatLocaleDateString(new Date("2024-08-06").getTime()) as string,
            email: "mock_06@gmail.com",
            jobPostingId,
            full_name: "Marry",
            phone_number: "0123456789",
            resume: "https://www.linkedin.com/in/hoaidien93/",
            years_of_experience: 1,
            status: ECandidateStatus.PHONE_SCREEN,
            score: 71,
            address: "Hanoi, Vietnam",
            certifications: [],
            education: [
                {
                    degree: "Bachelor",
                    major: "Software Engineering",
                    university: "Hanoi University of Science and Technology",
                    graduation_date: "2020-07-01"
                }
            ],
            hobbies: "Reading book, playing football",
            projects: [
                {
                    title: "HR Management System",
                    description: "A system to manage employee information",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01",
                    link: ""
                }
            ],
            languages: 'English, Vietnamese, Japanese',
            references: "Mr. A, Mr. B",
            skills: [
                "ReactJS",
                "NodeJS",
                "Docker"
            ],
            summary: "I am a software engineer with 5 years of experience in web development. I have a strong background in frontend development with ReactJS and backend development with NodeJS. I am also familiar with Docker and Kubernetes. I am looking for a new opportunity to grow my career and learn new technologies.",
            work_experience: [
                {
                    company: "ABC Company",
                    title: "Software Engineer",
                    description: "Developed web applications using ReactJS and NodeJS. Worked with the team to design and implement new features. Participated in code review and testing.",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01"
                }
            ]

        },
        {
            _id: nanoid(),
            date_of_birth: formatLocaleDateString(new Date("1998-01-01").getTime()) as string,
            date_of_application: formatLocaleDateString(new Date("2024-08-02").getTime()) as string,
            email: "mock_01@gmail.com",
            jobPostingId,
            full_name: "Mark",
            phone_number: "0123456789",
            resume: "https://www.linkedin.com/in/hoaidien93/",
            years_of_experience: 7,
            status: ECandidateStatus.HIRED,
            score: 98,
            address: "Hanoi, Vietnam",
            certifications: [],
            education: [
                {
                    degree: "Bachelor",
                    major: "Software Engineering",
                    university: "Hanoi University of Science and Technology",
                    graduation_date: "2020-07-01"
                }
            ],
            hobbies: "Reading book, playing football",
            projects: [
                {
                    title: "HR Management System",
                    description: "A system to manage employee information",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01",
                    link: ""
                }
            ],
            languages: 'English, Vietnamese, Japanese',
            references: "Mr. A, Mr. B",
            skills: [
                "ReactJS",
                "NodeJS",
                "Docker"
            ],
            summary: "I am a software engineer with 5 years of experience in web development. I have a strong background in frontend development with ReactJS and backend development with NodeJS. I am also familiar with Docker and Kubernetes. I am looking for a new opportunity to grow my career and learn new technologies.",
            work_experience: [
                {
                    company: "ABC Company",
                    title: "Software Engineer",
                    description: "Developed web applications using ReactJS and NodeJS. Worked with the team to design and implement new features. Participated in code review and testing.",
                    start_date: "2020-01-01",
                    end_date: "2020-07-01"
                }
            ]

        },
    ]
}

export const MOCKCANDIDATE = {
    "full_name": "Do Hoai Dien",
    "email": "hoaidien93@gmail.com",
    "date_of_birth": "05/10/1996",
    "phone_number": "0772000596",
    "address": "Ho Chi Minh City, Viet Nam",
    "years_of_experience": 5,
    "work_experience": [
        {
            "title": "Senior Fullstack Developer",
            "company": "Presight",
            "start_date": "May 2022",
            "end_date": "now",
            "description": "Presight is a BI tool which combines core features of Power BI, Notion, Google Sheet for helping Finance team to build the dashboard and collaborate through data easily. Responsibilities included building code structure & system, maintaining and reviewing code, and working with the product team to develop new features. Technical stack: React JS, Monorepo, NodeJS (Nest JS), Microservice, MongoDB, Docker, AWS."
        },
        {
            "title": "Senior Fullstack Developer - Team Leader",
            "company": "Nhan Luc Sieu Viet Company",
            "start_date": "Nov 2020",
            "end_date": "May 2022",
            "description": "SieuViet is the leading player in the online recruitment industry in Vietnam. Responsibilities included developing new features, maintaining source code, reviewing code, building code structure & system, and managing team members & working processes. Technical stack: React JS, VueJS, Next JS, NodeJS (Nest JS), PHP, Docker, AWS."
        },
        {
            "title": "Software Developer Collaborator",
            "company": "VNG Corporation",
            "start_date": "March 2019",
            "end_date": "Nov 2020",
            "description": "Developed internal tools such as it.vng.com.vn, hopquy. Responsibilities included developing new features, maintaining source code, and reviewing code. Technical stack: HTML/CSS, JS, PHP, NodeJS (Express), Cordova."
        },
        {
            "title": "Back End Developer Intern",
            "company": "Eplatform Solution Company",
            "start_date": "Sep 2018",
            "end_date": "March 2019",
            "description": "Worked on Japanese projects. Responsibilities included converting HTML to Wordpress theme and developing new features. Technical stack: HTML/CSS, JS, VueJS, Wordpress, Laravel."
        }
    ],
    "education": [
        {
            "degree": "Bachelor",
            "major": "Software Engineer",
            "university": "Ho Chi Minh University Of Science",
            "graduation_date": ""
        }
    ],
    "skills": [
        "Back-end: Node JS (NodeJS, NesJS), PHP",
        "Front-end: React JS, Vue JS, Next JS, HTML5/CSS3, Tailwind, Bootstrap",
        "Server: Ubuntu, AWS, Docker, CI/CD",
        "Database: SQL (MySQL, Postgres), NoSQL (MongoDB)",
        "Soft skills: teamwork, logical thinking, self-study",
        "Others: Algorithm, problem solving"
    ],
    "certifications": [],
    "projects": [],
    "languages": "",
    "hobbies": "",
    "references": "",
    "summary": "Seeking a position of fullstack developer to improve my soft skills, work in a professional environment, and gain more experience and knowledge in software development."
}
