import React from "react"
import { HRButton } from "../../../components/button"
import { ICAdd } from "../../../icons"
import { Filter } from "./filter"
import { JobPostingList } from "./list"
import { TJobPosting } from "../type"
import { useJobPosting } from "../../../hooks/repository/useJobPosting"
import { CreateNewJobPosting } from "./create-new"
import { useDispatch } from "react-redux"
import { setLoading } from "../../../redux/slices/appInfo"
import { useNotification } from "../../../hooks/useNotification"

export const JobPosting: React.FC = () => {
    const dispatch = useDispatch();
    const { showSuccess } = useNotification();
    const [isCreateNew, setIsCreateNew] = React.useState(false);
    const { createJobPosting } = useJobPosting();
    const onCreateJobPosting = React.useCallback((jobPosting: TJobPosting) => {
        dispatch(setLoading(true));
        // Mock Async
        setTimeout(() => {
            createJobPosting(jobPosting).then(() => {
                showSuccess("Created", "Job Posting has been created successfully");
                setIsCreateNew(false);
            }).finally(() => {
                dispatch(setLoading(false));
            });
        }, 1000);
    }, [createJobPosting]);
    return <div className="text-center">
        <div className="pt-8 space-y-6 min-h-screen">
            {
                isCreateNew ? <CreateNewJobPosting
                    onBack={() => setIsCreateNew(false)}
                    onCreated={onCreateJobPosting}
                /> : <JobPostingListRenderer
                    onCreate={() => setIsCreateNew(true)}
                />
            }
        </div>
    </div >
}

type JobPostingListRendererProps = {
    onCreate: () => void;
}
const JobPostingListRenderer: React.FC<JobPostingListRendererProps> = ({ onCreate }) => {
    const { getJobPostings } = useJobPosting();
    const [jobPostings, setJobPostings] = React.useState<TJobPosting[]>([]);
    React.useEffect(() => {
        getJobPostings().then(setJobPostings);
    }, [])
    return <div className="pb-16 space-y-8 text-left container mx-auto">
        <div className="flex items-center justify-between">
            <div className="flex items-center text-heading-5-semi-bold text-high-em" >
                <span>
                    List of Job Postings
                </span>
            </div>
            <div>
                <HRButton btnSize="md" onClick={onCreate}>
                    <div className="flex space-x-2 justify-center">
                        <ICAdd />
                        <span>Create new Job Post</span>
                    </div>
                </HRButton>
            </div>
        </div>
        <Filter onChange={() => { }} />
        <JobPostingList
            items={jobPostings}
            onChange={() => { }}
        />
    </div>
}