import { Menu, MenuProps } from "antd"

export type SecondaryMenuItem = Required<MenuProps>['items'][number];
export type SecondaryMenuProps = {
    items: SecondaryMenuItem[],
    current?: string
}


export const SecondaryMenu: React.FC<SecondaryMenuProps> = ({
    items,
    current
}) => {
    return <div className="bg-white border-t">
        <div className="container mx-auto relative">
            <Menu
                selectedKeys={current ? [current] : []}
                mode="horizontal"
                items={items}
            />
        </div>
    </div>
}
