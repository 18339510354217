import React from "react"
import { HRInput } from "../../../components/form/input";
import { ICSearch } from "../../../icons";
import { HRSelect } from "../../../components/form/select";

type FilterProps = {
    onChange: (value: any) => void;
}

export const Filter: React.FC<FilterProps> = ({
    onChange,
}) => {
    return (
        <div className="container mx-auto flex justify-between">
            <div className="grid grid-cols-1 gap-[30px] w-full">
                <div className="grid grid-cols-1 gap-[16px] w-full">
                    <div className="grid grid-cols-4 gap-[16px]">
                        <div className="pt-[24px]">
                            <HRInput
                                placeholder="Enter job title"
                                hasClearIcon={true}
                                maxLength={50}
                                prefix={<ICSearch />}
                            />
                        </div>
                        <HRSelect
                            label="Status"
                            placeholder="Select status"
                            className="w-full"
                        />
                        <HRSelect
                            label="Department"
                            placeholder="Select department"
                            className="w-full"
                        />
                        <HRSelect
                            name="position"
                            label="Position"
                            placeholder="Select position"
                            className="w-full"
                        />
                    </div>
                </div>

            </div>

        </div>
    )
}
