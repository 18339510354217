import { Button, Popover, Row, Table, Tag, TagType } from "antd"
import { ColumnsType } from "antd/lib/table";
import React from "react";
import classNames from "classnames";
import { HRPagination, IPagination } from "../../../components/pagination";
import { ICDelete, ICEdit, ICForward, ICMoreVertical, ICNoneData, ICViewWhite } from "../../../icons";
import styled from "styled-components";
import { formatLocaleDateString } from "../../../utils/date";
import { EJobPostingStatus, TJobPosting } from "../type";
import { useNavigation } from "../../../hooks/useNavigate";
import { Link } from "react-router-dom";
import { DeleteOutlined, FilterOutlined, FunnelPlotOutlined } from "@ant-design/icons";

const WrapperTable = styled.div`
    .ant-table-thead>tr>th {
        border: 0;
    }
    .ant-table-tbody>tr.ant-table-row>td {
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:first-child {
        border-left: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:last-child {
        border-right: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:first-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 12px;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:last-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 12px;
    }
`;
function getTagColor(status: EJobPostingStatus): string {
    switch (status) {
        case EJobPostingStatus.OPEN:
            return "green"
        case EJobPostingStatus.PENDING:
            return "orange"
        case EJobPostingStatus.CLOSED:
            return "red";
        default:
            return '#000';
    }
}

const columns: ColumnsType<TJobPosting> = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    // {
    //     title: 'Position',
    //     dataIndex: 'position',
    //     key: 'position',
    // },
    {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status: EJobPostingStatus) => <Tag color={getTagColor(status)}> {status} </Tag>
    },
    {
        title: 'Total Candidates',
        dataIndex: 'totalCandidates',
        key: 'totalCandidates',
        width: '170px',
        render: (totalCandidates: number) => {
            return <div className="pr-2 text-body text-high-em">
                <span>{totalCandidates ?? 0}</span>
            </div>
        }
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        width: '220px',
        render: (lastActiveAt: number) => {
            return <div className="pr-2 text-body text-high-em">
                <span>{formatLocaleDateString(lastActiveAt)}</span>
            </div>
        }
    },
    {
        title: '',
        dataIndex: '_id',
        key: '_id',
        width: '40px',
        render: (_id: string) => {
            return <ItemActions id={_id} />
        }
    }
]

type JobPostingListProps = {
    items: TJobPosting[];
    pagination?: IPagination;
    onChange: ({ page }: { page: number }) => void;
}
type TableContext = {
    keyword: string;
}
const TableContext = React.createContext<TableContext>({
    keyword: '',
} as TableContext);

export const JobPostingList: React.FC<JobPostingListProps> = ({
    items,
    pagination,
    onChange,
}) => {
    const { navigate } = useNavigation();
    const onChangePaging = (page: number) => {
        onChange({ page })
    };
    return <WrapperTable className="grid grid-cols-1 shadow-e-03 text-g-400 overflow-hidden box-border rounded-[12px]">
        <Table
            className={classNames("white-header")}
            columns={columns}
            dataSource={items}
            rowKey="_id"
            rowClassName="cursor-pointer data-row"
            locale={{
                emptyText: <EmptyTable />
            }}
            pagination={false}
        />
        {pagination && pagination.totalPages > 1 && <Row gutter={16}
            style={{
                margin: 0,
            }}
            className="grid items-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]">
            <HRPagination
                onChange={onChangePaging}
                defaultCurrent={pagination?.page}
                defaultPageSize={pagination?.limit}
                total={pagination?.totalDocuments}
                currentPage={pagination?.page}
            />
        </Row>}
    </WrapperTable>
}

const EmptyTable = () => {
    return (
        <div className="my-6 flex flex-col gap-[32px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                No data found
            </div>
        </div>
    )
}


type ItemActionsProps = {
    id: string;
}
export const ItemActions: React.FC<ItemActionsProps> = ({
    id,
}) => {
    const [open, setOpen] = React.useState(false);
    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [])
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    return (
        <div className="text-center">
            <Popover
                open={open}
                placement="bottomRight"
                content={<div className="flex flex-col min-w-[206px] py-[12px]">
                    <Link to={`/recruitment/hiring-process?id=${id}`}>
                        <div className="flex gap-[16px] items-center px-4 py-2 hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                            <FunnelPlotOutlined />
                            <span>View process</span>
                        </div>
                    </Link>
                    <div className="flex gap-[16px] items-center px-4 py-2 hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                        <DeleteOutlined />
                        <span>Delete</span>
                    </div>
                </div>}
                showArrow={false}
                className="popover-more"
                prefixCls="hr-popover ant-popover"
                trigger="click"
                onOpenChange={handleOpenChange}
            >
                <Button ghost style={{
                    width: "32px",
                    height: "32px",
                    padding: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                    onClick={(event) => event.stopPropagation()}>
                    <ICMoreVertical />
                </Button>
            </Popover>
        </div>
    )
}
