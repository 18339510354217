
export const WHITE_LIST_IMAGE_TYPES = ["image/png", "image/jpeg", "image/jpg"];
export const BACKGROUND_MAX_WIDTH = 1920;
export const BACKGROUND_MAX_HEIGHT = 1080;
export const AVATAR_MAX_WIDTH = 1080;
export const AVATAR_MAX_HEIGHT = 720;
export const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
export const NAME_REGEX = new RegExp(`^[a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ .);('-]+$`);

export enum LANGUAGE {
    VN = "vn",
    EN = "en",
};