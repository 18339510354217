import { Card, Statistic } from "antd"
import { Bar, Doughnut, Line } from "react-chartjs-2"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    ArcElement,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement);

// Data for the line chart (Total Active Jobs from July 2023 to July 2024)
const lineData = {
    labels: [
        '2023-07', '2023-08', '2023-09', '2023-10', '2023-11', '2023-12',
        '2024-01', '2024-02', '2024-03', '2024-04', '2024-05', '2024-06', '2024-07'
    ],
    datasets: [
        {
            label: 'Total Active Jobs',
            fill: false,
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            data: [
                1, 4, 3, 5, 2, 3, 4, 5, 6, 7, 8, 9, 15
            ],
        },
    ],
};
// Data for the donut chart (Breakdown by Candidate Status)
const donutData = {
    labels: ['Active', 'Interviewing', 'Offered', 'Hired'],
    datasets: [
        {
            label: 'Candidates',
            data: [120, 60, 30, 40], // Example data for each status
            backgroundColor: [
                '#FF9F40', // Orange
                '#FF6384', // Red
                '#4BC0C0', // Teal
                '#FFCE56', // Yellow
                '#9966FF', // Purple
              ],
              hoverBackgroundColor: [
                '#FF8C1A', // Darker Orange
                '#FF3D67', // Darker Red
                '#36A39E', // Darker Teal
                '#FFB74D', // Darker Yellow
                '#8E5DDD', // Darker Purple
              ],
            borderWidth: 1,
        },
    ],
};
// Data for the donut chart (Breakdown by Department)
const donutDepartmentData = {
    labels: ['Engineering', 'Marketing', 'Sales', 'HR', 'Finance'],
    datasets: [
        {
            label: 'Candidates per Department',
            data: [40, 25, 30, 15, 20], // Example data for each department
            backgroundColor: [
                '#FF6384', // Red
                '#36A2EB', // Blue
                '#FFCE56', // Yellow
                '#4BC0C0', // Teal
                '#9966FF', // Purple
              ],
              hoverBackgroundColor: [
                '#FF3D67', // Darker Red
                '#2C9BE8', // Darker Blue
                '#FFB74D', // Darker Yellow
                '#36A39E', // Darker Teal
                '#8E5DDD', // Darker Purple
              ],
            borderWidth: 1,
        },
    ],
};
export const StatisticsPage: React.FC = () => {
    return <div className="text-center">
        <div className="pt-8 space-y-6 min-h-screen">
            <div className="space-y-8 text-left container mx-auto">
                <div className="flex items-center justify-between">
                    <div className="flex items-center text-heading-5-semi-bold text-high-em" >
                        <span>
                            Statistics
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-4">
                    <Card>
                        <Statistic
                            title="Active Jobs"
                            valueRender={
                                () => <div>
                                    <span className="font-bold">15</span>
                                    <span className="text-[green] ml-1 font-semibold text-[17px]">+25%</span>
                                </div>
                            }
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Total Candidates"
                            valueRender={() => <div>
                                <span className="font-bold">65</span>
                                <span className="text-[green] ml-1 font-semibold text-[17px]">+15%</span>
                            </div>}
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Rejected Candidates"
                            valueRender={() => <div>
                                <span className="font-bold">10</span>
                                <span className="text-[red] ml-1 font-semibold text-[17px]">-20%</span>
                            </div>}
                        />
                    </Card>
                    <Card>
                        <Statistic
                            title="Hired Candidates"
                            valueRender={() => <div>
                                <span className="font-bold">15</span>
                                <span className="text-[green] ml-1 font-semibold text-[17px]">+10%</span>
                            </div>}
                        />
                    </Card>
                </div>
                <div className="space-y-4">
                    {/* Line Chart for Total Active Jobs */}
                    <Card title="Total Active Jobs (July 2023 - July 2024)" bordered={false}>
                        <Line data={lineData} />
                    </Card>
                    <div className="grid grid-cols-2 gap-6">
                        {/* Donut Chart for Breakdown by Candidate Status */}
                        <Card title="Candidate Status Breakdown" bordered={false}>
                            <Doughnut data={donutData} />
                        </Card>
                        {/* Donut Chart for Breakdown by Candidate Status */}
                        <Card title="Candidate Breakdown by Deparment" bordered={false}>
                            <Doughnut data={donutDepartmentData} />
                        </Card>
                    </div>

                </div>
            </div>
        </div>
    </div>
}