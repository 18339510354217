import React from "react"
import { ECandidateStatus, TCandidate, TJobCandidate, TJobPosting } from "../type"
import { useCandidates } from "../../../hooks/repository/useCandidates";
import { useJobPosting } from "../../../hooks/repository/useJobPosting";
import { useAsyncMemo } from "../../../hooks/useAsyncMemo";
import { useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import Board from 'react-trello-ts'
import _ from "lodash";
import { Tag } from "antd";
import { HRButton } from "../../../components/button";
import { ICAdd } from "../../../icons";
import { AddCandidateModal } from "./add-candidate-modal";
import { nanoid } from "@reduxjs/toolkit";
import { SelectedCandidateDrawer } from "./SelectedCandidateDrawer";
export function getScoreColor(score: number): string {
    if (score < 50) {
        return "#FFEBEE"
    }
    if (score < 70) {
        return "#FFF9C4"
    }
    if (score < 90) {
        return "#E0F7FA"
    }
    return "#C8E6C9"
}
function mapCandidateToCard(candidate: TJobCandidate): any {
    return {
        id: candidate._id,
        title: <div className="text-[12px]">{candidate.full_name}</div>,
        description: <div>
            <div className="text-[12px]">{candidate.email}</div>
            <div className="text-[12px]">{candidate.phone_number}</div>
            <div className="flex items-center justify-between">
                <Tag color="blue">{candidate.years_of_experience} YOE</Tag>
                <span
                    className="w-6 h-6 flex items-center justify-center rounded-full text-[10px] font-semibold"
                    style={{
                        backgroundColor: getScoreColor(candidate.score || 0)
                    }}
                >
                    {candidate.score}
                </span>
            </div>

        </div>,
        style: {
            maxWidth: "200px",
            minWidth: "200px",
            fontSize: "12px",
        }
    }
}
function getBackgroundColor(status: ECandidateStatus): string {
    switch (status) {
        case ECandidateStatus.APPLIED:
            return "rgb(162 167 249)"
        case ECandidateStatus.PHONE_SCREEN:
            return "#E0F7FA"
        case ECandidateStatus.INTERVIEWED:
            return "#FFEBEE"
        case ECandidateStatus.OFFERED:
            return "#F0F4C3"
        case ECandidateStatus.REJECTED:
            return "rgb(248 162 190)"
        case ECandidateStatus.HIRED:
            return "#15c615"
        default:
            return "#FFFFFF"
    }
}
function buildLanes(candidates: TJobCandidate[]): any[] {
    const candidateByStatus = _.groupBy(candidates, "status");
    return [
        ECandidateStatus.APPLIED,
        ECandidateStatus.PHONE_SCREEN,
        ECandidateStatus.INTERVIEWED,
        ECandidateStatus.OFFERED,
        ECandidateStatus.REJECTED,
        ECandidateStatus.HIRED
    ].map((status) => {
        return {
            id: status,
            title: <div className="text-sm">{status}</div>,
            cards: candidateByStatus[status]?.map(mapCandidateToCard) || [],
            style: {
                backgroundColor: getBackgroundColor(status),
            },
            label: <div className="text-[12px]">
                {candidateByStatus[status]?.length || 0}/{candidates.length}
            </div>
        }
    })
}

export const HiringProcess: React.FC = () => {
    const { search } = useLocation();
    const [selectedCandidateId, setSelectedCandidateId] = React.useState<string | undefined>();
    const id = React.useMemo(() => new URLSearchParams(search).get("id") ?? "1", [search]);
    React.useEffect(() => {
        const candidateId = new URLSearchParams(search).get("candidateId");
        if (!candidateId) return;
        setSelectedCandidateId(candidateId);
    }, [search]);
    const { getCandidates, createCandidate } = useCandidates();
    const { getDetailJobPosting } = useJobPosting();
    const detailJobPosting = useAsyncMemo(() => {
        if (!id) return Promise.resolve(undefined);
        return getDetailJobPosting(id);
    }, [id], undefined);
    const [candidates, setCandidates] = React.useState<TJobCandidate[]>([]);
    const selectedCandidate = React.useMemo(() => {
        return candidates.find(candidate => candidate._id === selectedCandidateId);
    }, [selectedCandidateId, candidates]);
    React.useEffect(() => {
        if (id) {
            getCandidates(id).then((candidates) => {
                setCandidates(candidates);
            })
        }
    }, [id]);

    const [lanes, setLanes] = React.useState<any[]>([]);
    React.useEffect(() => {
        if (candidates.length) {
            setLanes(buildLanes(candidates));
        }
    }, [candidates]);
    const [boardKey, setBoardKey] = React.useState(0);
    const onAddCandidate = React.useCallback((candidate: TCandidate) => {
        const jobPostingCandidate = {
            ...candidate,
            date_of_application: new Date().toISOString(),
            jobPostingId: id,
            resume: "",
            status: ECandidateStatus.APPLIED,
            _id: nanoid()
        }
        createCandidate(jobPostingCandidate);
        setCandidates(prev => [
            ...prev,
            jobPostingCandidate
        ]);
        setTimeout(() => {
            setBoardKey((prev) => prev + 1);
        }, 10)
    }, [id, createCandidate]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const onCardClick = React.useCallback((cardId: string) => {
        setSelectedCandidateId(cardId);
    }, []);
    const onDataChange = React.useCallback((board: any) => {
        setLanes(board.lanes);
    }, []);
    if (!detailJobPosting) return <div>Job not found</div>
    return <div className="text-center">
        <div className="pt-8 space-y-8 min-h-screen">
            <div className="space-y-8 text-left container mx-auto">
                <div className="flex items-center justify-between">
                    <div className="flex items-center text-heading-5-semi-bold text-high-em" >
                        {detailJobPosting.title}
                    </div>
                    <div>
                        <HRButton btnSize="md" onClick={() => setIsModalOpen(true)}>
                            <div className="flex space-x-2 justify-center">
                                <ICAdd />
                                <span>Add candidate</span>
                            </div>
                        </HRButton>
                    </div>
                </div>

            </div>
            <div className="pt-4 text-left px-4" style={{
                maxWidth: `calc(100vw - 32px)`,
                margin: "0 auto"
            }}>
                <div className="mx-auto w-max">
                    <BoardContainer
                        key={boardKey}
                        lanes={lanes}
                        onDataChange={onDataChange}
                        onCardClick={onCardClick}
                    />
                </div>
            </div>
        </div>
        {isModalOpen && <AddCandidateModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onAddCandidate={onAddCandidate}
        />}
        {
            selectedCandidate && <SelectedCandidateDrawer
                isOpen={!!selectedCandidateId}
                candidate={selectedCandidate}
                onClose={() => setSelectedCandidateId(undefined)}
            />
        }
    </div>
}
type BoardContainerProps = {
    lanes: any[];
    onDataChange: (board: any) => void;
    onCardClick: (cardId: string) => void;
}
const BoardContainer = React.memo<BoardContainerProps>(({
    lanes,
    onDataChange,
    onCardClick
}) => {
    return <Board
        data={{ lanes }}
        onDataChange={onDataChange}
        onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId) => {
            console.log('onCardMoveAcrossLanes', fromLaneId, toLaneId, cardId)
        }}
        onCardClick={onCardClick}
        laneStyle={{
            borderRadius: "2px",
            padding: "16px",
            margin: "8px",
            minHeight: "80px",
            color: "#fff",
            fontSize: "14px",
            width: "225px",
        }}
        style={{
            backgroundColor: "transparent",
            width: "max-content",
        }}
        cardStyle={{
            borderRadius: "2px",
            padding: "16px",
            margin: "8px",
            minHeight: "80px",
            color: "#fff",
            fontSize: "14px",
            width: "200px",
        }}
        className="mx-auto"
    />
})