import React from "react";

export function useAsyncMemo<T, TInitial extends T | undefined>(
    asyncFunction: () => Promise<T>,
    deps: React.DependencyList,
    initialValue: TInitial = undefined as TInitial
): TInitial extends undefined ? T | undefined : T {
    const [value, setValue] = React.useState<T | undefined>(initialValue);
    React.useEffect(() => {
        let mounted = true;
        asyncFunction().then((value: T) => {
            if (mounted) {
                setValue(value);
            }
        });
        return () => {
            setValue(initialValue);
            mounted = false;
        };
    }, deps);
    return value as TInitial extends undefined ? T | undefined : T;
}